import React from "react"
import Button from "../../components/button"
import img from "../../images/image (9).svg"
import { Link } from "gatsby"
import Layout from "../../layout/layout"
// import Button from "../components/button"
import "../../styles/homestay.css"
const Accreditation = () => {
  return (
    <Layout
      title="Homestay"
      description=""
    >
      <div className="layout-container homestay-container" style={{marginBottom:"0px"}}>
        <div className="content">
        <div className="qualification-crumbs">
            Global University / Accreditation
          </div>
        <p className="p-lg">Summit Institute is a Network Office for Global Univeristy (USA) providing accredited enrolment & support options for students in New Zealand and abroad. This website provides course information on undergraduate study options that are USA recognised and it is our intention to provide graduate studies options in the near future. Contact us for more information.</p>
        <div className="accreditation section1" style={{display:"flex", gap:"50px", justifyContent:"center", alignItems:"start"}}>
          <div>
          <a href="https://www.hlcommission.org/mark-of-accreditation-status-verification?104108099+118097108105100097116105111110+108111103111046112110103+104116116112115058047047103108111098097108117110105118101114115105116121046101100117047&UGxEQk3X8u%2f0Q0X9Ugqaq8T7MOS2%2f2L1vP0TztPCH1E%3d" target="blank"><img src={img} alt=" Status Verification " style={{marginTop:"40px",}}/></a>
          </div>
          <div>
            <h4>Accreditation</h4>
            <p className="p-lg">Global University is listed as an accredited institution on the United States Department of Education website: <a href="http://ope.ed.gov/accreditation/Search.aspx" target="_blank">http://ope.ed.gov/accreditation/Search.aspx</a></p>
            <p className="p-lg">Accredited by the Higher Learning Commission</p>
            <p className="p-lg">230 South LaSalle Street,</p>
            <p className="p-lg">Suite 7-500 Chicago, IL 60604</p>
            <p className="p-lg">Telephone: (800) 621-7440</p>
            <p className="p-lg">Website: <a href="https://www.hlcommission.org" target="_blank">https://www.hlcommission.org</a></p>
            <p className="p-lg">Association for Biblical Higher Education Commission on Accreditation</p>
            <p className="p-lg">Global University holds applicant status with the Association for Biblical Higher Education Commission on Accreditation</p>
            <div style={{marginTop:"30px"}}>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
          </div>
        </div>
        </div>
      </div>
      
    </Layout>
  )
}

export default Accreditation
